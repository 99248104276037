import * as React from 'react'
import { Flex } from 'grid-styled'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { Helmet } from 'react-helmet'
import Hero from '../../components/Hero'
import Button from '../../components/Button'
import BackgroundSection from '../../components/BackgroundSection'
import MainLayout from '../../components/Layout'
import { AboutContact } from '..'
import {
  BeforeAfter,
  StyledTabs,
  StyledTabList,
  StyledTab,
  Content,
} from '../../components/procedures'
import { TabPanel } from 'react-tabs'

const content = {
  backgroundTitle: 'Other Facial Options',
  subtitle: `Trusted, Experienced & Ethical. Boutique plastic surgery on Sydney's North Shore.`,
  title: 'Facelift',
  otherOptions: [
    // {
    //   text: 'Facelift',
    //   to: '/procedures/facelift',
    // },
    {
      text: 'Nose Surgery',
      to: '/procedures/nose-surgery',
    },
    {
      text: 'Ear Correction',
      to: '/procedures/ear-correction',
    },
    {
      text: 'Earlobe Repair',
      to: '/procedures/ear-reconstruction',
    },
    {
      text: 'Eyelid Surgery',
      to: '/procedures/eyelid-surgery',
    },
  ],
}

export default ({ data }) => (
  <MainLayout header="light">
    <Helmet>
      <title>Facelift in Sydney - Dr. Graham Sellars</title>
      <meta
        name="description"
        content="Dr. Graham Sellars offers natural-looking facelifts in Sydney, NSW. Start looking and feeling younger with our cosmetic procedures."
      />
    </Helmet>

    <Hero bgImage={data.womanWaterfall.childImageSharp} height="600px">
      <h1>{content.title}</h1>
      <p>{content.subtitle}</p>
      <Flex>
        <Button color="#fff" small={true} outline={true}>
          Learn more
        </Button>
      </Flex>
    </Hero>

    <Content>
      <h2>Facelift</h2>
      <h4>
        Facelift surgery is performed under general anaesthesia, with the
        operation taking 3-4 hours, and usually requires an overnight stay in
        hospital.
      </h4>

      <p>
        There are many different types of facelift, but the longest lasting
        techniques involve tightening the deeper muscle layer of the face as
        well as the skin. Tightening the muscle layer gives a longer lasting
        result and reduces the tension on the skin closure, producing better
        scars.
      </p>

      <p>
        Incisions are hidden mainly within the hairline, starting in the natural
        crease in front of the ear, going under and behind the earlobe and back
        into the scalp, and usually there is also a small incision under the
        chin. The muscle layer is tightened, with any excess fat being removed,
        and the loose excess skin is then removed and the wound closed.
      </p>

      <p>Please contact us below to learn more about Facelift Surgery:</p>

      <Link to="/contact/">
        <Button inverted={true}>Contact us today!</Button>
      </Link>
    </Content>

    <BackgroundSection
      image={data.otherBreast.childImageSharp}
      title={content.backgroundTitle}
      height="500px"
      darkness="0.4"
    >
      <p style={{ fontSize: 18, margin: '-1rem 0 2rem' }}>
        Browse Dr. Graham Sellars' full range of surgical and non-surgical
        procedures.
      </p>
      {content.otherOptions.map(({ to, text }) => (
        <Flex mr={2} mb={3} style={{ float: 'left' }}>
          <Link to={to}>
            <Button small={true} color="#fff" outline={true}>
              {text}
            </Button>
          </Link>
        </Flex>
      ))}
    </BackgroundSection>

    <StyledTabs style={{ marginTop: 50 }}>
      <h2>Information about {content.title}</h2>
      <StyledTabList>
        <StyledTab>The Surgery</StyledTab>
        <StyledTab>Post operative care</StyledTab>
        <StyledTab>Risks of Surgery</StyledTab>
      </StyledTabList>

      <TabPanel>
        <h4>Preparing for Surgery</h4>
        <p>
          It is important to stop smoking at least 6 weeks before the surgery –
          smoking slows blood flow to the skin and increases the chance of
          healing problems after the surgery. Aspirin, anti-inflammatory
          medications for arthritis, and some vitamins and herbal medications
          can cause bleeding and also need to be stopped 10 days before the
          surgery.
        </p>

        <h4>Facelift Surgery</h4>
        <p>
          Facelift surgery is performed under general anaesthesia, with the
          operation taking 3-4 hours, and usually requires an overnight stay in
          hospital.
        </p>

        <p>
          There are many different types of facelift, but the longest lasting
          techniques involve tightening the deeper muscle layer of the face as
          well as the skin. Tightening the muscle layer gives a longer lasting
          result and reduces the tension on the skin closure, producing better
          scars.
        </p>

        <p>
          Incisions are hidden mainly within the hairline, starting in the
          natural crease in front of the ear, going under and behind the earlobe
          and back into the scalp, and usually there is also a small incision
          under the chin. The muscle layer is tightened, with any excess fat
          being removed, and the loose excess skin is then removed and the wound
          closed.
        </p>
      </TabPanel>
      <TabPanel>
        <h4>
          After the surgery it is important to rest and minimize activity, with
          no lifting, driving, or heavy duties for 10-14 days.
        </h4>
        <p>
          The face is usually wrapped tightly within a bandage for the first day
          to keep the surgical dressings in place, and this is changed the next
          day. Before leaving hospital, a lighter support bandage is usually
          supplied, which is recommended to stay on for a couple of weeks.
        </p>

        <p>
          You will experience some bruising and swelling immediately after the
          surgery, and these will reduce and disappear over a week or two. After
          a few days you can wear makeup to help conceal the bruising. You will
          have some areas of numbness and tightness, which gradually disappears
          over a few weeks.
        </p>

        <h4>
          The surgical sutures are usually removed one week after surgery.
        </h4>

        <p>
          The scars from the surgery fade with time and are usually not
          noticeable, although occasionally they require further treatment.
        </p>

        <p>
          During the healing period (2-4 weeks), you will be able to resume most
          of your normal activities. Medication is generally prescribed during
          the post-operative period, for pain relief if required, but medication
          containing asprin should be avoided as it can thin the blood, and
          increase the chances of bruising.
        </p>

        <p>Patients usually need 2-4 weeks off work.</p>
      </TabPanel>
    </StyledTabs>

    <AboutContact data={data} />
  </MainLayout>
)
export const query = graphql`
  query FaceliftQuery {
    womanWaterfall: file(relativePath: { eq: "background-generic.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    otherBreast: file(relativePath: { eq: "face-close-up.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    asaps: file(relativePath: { eq: "ASAPS.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    asps: file(relativePath: { eq: "ASPS.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    drSellars: file(relativePath: { eq: "dr-graham-sellars.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    fracs: file(relativePath: { eq: "FRACS.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
